import mutations from './mutations';

describe('mutations', () => {
  describe('toggleSceneEditor', () => {
    it('should toggle showSceneEditor from false to true', () => {
      const state = {
        overlay:
          { showSceneEditor: false }
      };

      mutations.toggleSceneEditor(state);

      expect(state.overlay.showSceneEditor).toBe(true);

      mutations.toggleSceneEditor(state);

      expect(state.overlay.showSceneEditor).toBe(false);
    });
  });

  describe('togglePlaybackExport', () => {
    it('should toggle showPlaybackExportModal from false to true', () => {
      const state = {
        overlay:
          { showPlaybackExportModal: false }
      };

      mutations.togglePlaybackExport(state);

      expect(state.overlay.showPlaybackExportModal).toBe(true);

      mutations.togglePlaybackExport(state);

      expect(state.overlay.showPlaybackExportModal).toBe(false);
    });
  });

  describe('hideAllOverlay', () => {
    it('should set state.overlay child value to false', () => {
      const state = {
        overlay: {
          showPlaybackExportModal: true,
          showSceneEditor: false,
        },
      };
      mutations.hideAllOverlay(state, { exclusiveKey: 'showSceneEditor' });

      expect(state.overlay).toEqual({
        showPlaybackExportModal: false,
        showSceneEditor: false,
      });
    });
  });

  describe('clearDirty', () => {
    it('should set dirty to false by key', () => {
      const state = {
        dirty: {
          test: true
        }
      };

      mutations.clearDirty(state, 'test');

      expect(state.dirty.test).toBe(false);
    });
  });

  describe('setURL', () => {
    it('should set URL', () => {
      const state = {
        navigationURL: ''
      };

      mutations.setURL(state, 'test');

      expect(state.navigationURL).toBe('test');
    });
  });

  describe('clearURL', () => {
    it('should clear URL to empty string', () => {
      const state = {
        navigationURL: 'test'
      };

      mutations.clearURL(state);

      expect(state.navigationURL).toBe('');
    });
  });

  describe('directToURL', () => {
    it('should do nothing to location.href if navigationURL is empty string', () => {
      const originalLocation = global.window.location;
      delete global.window.location;
      global.window.location = {
        href: 'test'
      };

      const state = {
        navigationURL: ''
      };
      expect(global.window.location.href).toBe('test');

      mutations.directToURL(state);

      expect(global.window.location.href).toBe('test');

      global.window.location = originalLocation;
    });

    it('should set new url if state.navigationURL is not empty', () => {
      const originalLocation = global.window.location;
      delete global.window.location;
      global.window.location = {
        href: 'test'
      };

      const state = {
        navigationURL: 'abc'
      };
      expect(global.window.location.href).toBe('test');

      mutations.directToURL(state);

      expect(global.window.location.href).toBe('abc');

      global.window.location = originalLocation;
    });
    it('should call reload', () => {
      const originalLocation = global.window.location;
      delete global.window.location;
      global.window.location = {
        href: 'test',
        reload: jest.fn()
      };

      const state = {
        navigationURL: 'test'
      };
      expect(global.window.location.href).toBe('test');

      mutations.directToURL(state);

      expect(global.window.location.reload).toHaveBeenCalledTimes(1);

      global.window.location = originalLocation;
    });
  });
});
